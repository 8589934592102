export const environment = {
    production: false,
    hmr: true,
    // baseUrl:'http://192.168.20.207:8000',
    // baseUrl:'http://192.168.20.141:8000',
    baseUrl:'https://ikooapi.elitetraveltech.in',

    defaultLanguage: 'Label',
    supportedLanguages: ['Label', 'TMSA'],
};
